var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height(),
    body = $('body'),
    bodyWrapper = $('.body-wrapper'),
    homeBanner = $('.js-home-banner'),
    innerBanner = $('.js-inner-banner'),
    header = $('.header'),
    footer = $('.footer'),
    menuBox = $('.js-menu-box'),
    bannerContent = $('.js-banner-content'),
    jsCoverSlider = $('.js-cover-slider'),
    headerHeight = $('.c-main-header').outerHeight(),
    jsCoverthumbSlider = $('.js-cover-thumb-slider'),
    availableScreen = winHeight - header.height() - footer.height() - 35,
    animationStartoffset = winHeight / 3;


$(function () {
    browserDetect();
    clickfunction();
    initFilterPopup();
    initMobSlider();
    validation('.form-validation');
    objectFitImages('img.objectfit', {
        watchMQ: true
    });
});

$(window).on('beforeunload', function () {
    $(window).scrollTop(0);
});

$(window).on('load', function () {
    // Do after Page ready
    onTopCheck();
    doOnLoad();
    addOnLoadAnimation();
    initPageFooter();
});

$(window).on('resize orientationchange', function () {
    // Do on resize
    winWidth = $(window).width(),
        winHeight = $(window).height();
    initMobSlider();
    initslidermob();
    addClassOnHover('.ins-thumb-wrapper');
    setBotNavBar();
    initPageFooter();
});

$(window).on('scroll', function () {
    headerFixed();
    counter();
});

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        if ($(bodyWrapper).hasClass('menu-open')) {
            $('.js-toggle-menu').removeClass('menu-open');
            $(bodyWrapper).removeClass('menu-open');
        };
    };
});

function doOnLoad() {
    getmenuSpacing();
    toggleMenu();
    calApplyHeights();
    initSelect2('.js-select2');
    initSelect2multiselect('.js-select2-multi');
    initDatepicker('.js-flatpickr');
    initRangeslider('.js-rangeSlider');
    initRangesliderPrice('.js-rangeSlider-price');
    initslider('.js-instructor-slider');
    headerFixed();
    checkFieldval($('.js-input-focus'));
    initMobSlider();
    addClassOnHover('.ins-thumb-wrapper');
    dataSrc();
    videoPlayerInit();
    addClassOnHover('.ins-thumb-wrapper');
    initslidermob();
    ChangeToSvg();
    setBotNavBar();
    loadRecaptcha('.js-recaptcha');
    counter();
    if ($('#map').length > 0) {
        initMap();
    }
    initFulscreen();
    dataTrim();
    if (winWidth < 992) {
        menuBoxHeight = $('.menu-box').height();
        bodyWrapper.css('padding-bottom', menuBoxHeight);
    }
    $('.sec-thank-you, .error-page-404').css('min-height', availableScreen);
    pageLoaded();
}

function clickfunction() {
    $('body').on('click', '.js-dropdownbtn:not(.is--active)', function () {
        dropdownclose();
        dropdownopen($(this));
    }).on('click', '.js-dropdownbtn.is--active', function () {
        dropdownclose();
    }).on('click', function (e) {
        var gdd = $('.dropdown-box');
        if (!gdd.is(e.target) && gdd.has(e.target).length === 0) {
            dropdownclose();
        }
    }).on('click', '.js-videoPopup', function () {
        videoPopup($(this));
    }).on('click touchstart', '.parent-cl', function (e) {
        $(this).parents('.video-box').toggleClass('play-video');
        var etarget = $(e.target);
        if (winWidth < 1300) {
            if (etarget.parents('.video-box').hasClass('play-video')) {
                etarget[0].play();
            } else {
                etarget[0].pause();
            }
        }
    });
}

function ChangeToSvg() {
    $('img.js-tosvg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' insvg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });
}

function getmenuSpacing() {
    var headerHeight = header.height();
    menuBox.css('padding-top', headerHeight);
    bannerContent.css('padding-top', headerHeight);
    if (!$(bodyWrapper).hasClass('temp-banner-page')) {
        if (winWidth < 768) {
            bodyWrapper.css('padding-top', headerHeight + 20);
        } else {
            bodyWrapper.css('padding-top', headerHeight + 30);
        }
    }
}

function toggleMenu() {
    $(document).on("click", ".js-toggle-menu", function (e) {
        e.preventDefault();
        $(this).toggleClass('menu-open');
        $(bodyWrapper).toggleClass('menu-open');
    });
    $(document).on("click", ".js-toggleMobileMenu", function (e) {
        e.preventDefault();
        $(this).toggleClass('menu-open');
        $('.js-mobSubmenu').slideToggle();
    });
}

function calApplyHeights() {
    homeBanner.css({
        'height': winHeight
    });
    innerBanner.css({
        'height': winHeight * 0.6
    });
}

function headerFixed() {
    var scroll = $(window).scrollTop();
    if (scroll < 30) {
        $(bodyWrapper).removeClass("header-fixed");
    } else {
        $(bodyWrapper).addClass("header-fixed");
    }
}

function browserDetect() {
    navigator.sayswho = (function () {
        var ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    $('body').addClass(navigator.sayswho);
}

function initSelect2(target) {
    var target = $(target);
    target.each(function (i, e) {
        var tarPar = $(e).parent('.select2-box');
        var tarPlace = $(e).data('placeholder');
        var dataClass = $(e).data('class');
        $(e).select2({
            dropdownParent: tarPar,
            minimumResultsForSearch: Infinity,
            width: '100%',
            placeholder: tarPlace,
            dir: isRTL,
            theme: dataClass,
        });
    });
}

function initSelect2multiselect(target) {
    var target = $(target);
    target.each(function (i, e) {
        var tarPar = $(e).parent('.select2-box');
        var tarPlace = $(e).data('placeholder');
        var dataClass = $(e).data('class');
        $(e).select2({
            dropdownParent: tarPar,
            minimumResultsForSearch: Infinity,
            width: '100%',
            placeholder: tarPlace,
            multiple: true,
            tags: false,
            dir: isRTL,
            closeOnSelect: false,
            theme: dataClass,
        });
        $(e).on('select2:opening select2:closing', function (event) {
            var $searchfield = $(e).parent().find('.select2-search__field');
            $searchfield.prop('disabled', true);
        });
        $(e).on('select2:select select2:unselect', function (evt) {
            var uldiv = $(e).siblings('span.select2').find('ul')
            var count = uldiv.find('li').length - 1;
            if (count == 0) {
                uldiv.html("<li>" + tarPlace + "</li>")
            } else {
                uldiv.html("<li>" + tarPlace + " (" + (count - 1) + ")</li>")
            }
        });
    });
}

function initDatepicker(target) {
    var target = $(target);
    target.each(function (i, e) {
        var tarPar = $(e).parent('.datepicker-box');
        $(e).flatpickr({
            dateFormat: "m-d-Y",
            mode: "range",
            minDate: "today",
            onReady: function (selectedDateCore, selectedDate, element) {
                $(element.weekdayContainer).find('.flatpickr-weekday').each(function (i, el) {
                    el.innerText = el.innerText.replace('Sun', 'S');
                    el.innerText = el.innerText.replace('Mon', 'M');
                    el.innerText = el.innerText.replace('Tue', 'T');
                    el.innerText = el.innerText.replace('Wed', 'W');
                    el.innerText = el.innerText.replace('Thu', 'T');
                    el.innerText = el.innerText.replace('Fri', 'F');
                    el.innerText = el.innerText.replace('Sat', 'S');
                });
            }
        });
    });
}

function initRangeslider(target) {
    var target = $(target);
    target.each(function (i, e) {
        $(e).ionRangeSlider({
            grid: false,
            min: 0,
            max: 40,
            from: 0,
            max_postfix: '+ hours',
            onStart: function (data) {
                $(data.input).parent('.rangeSlider').find('.js-rang-val').text(data.from);
                $(data.input).parents('.dropdown-box').find('.js-rang-val').text(data.from);
                if (data.from > 0) {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').hide();
                    $(data.input).parents('.dropdown-box').find('.palce-value').show();
                }
            },
            onChange: function (data) {
                $(data.input).parent('.rangeSlider').find('.js-rang-val').text(data.from);
                $(data.input).parents('.dropdown-box').find('.js-rang-val').text(data.from);
                if (data.from > 0) {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').hide();
                    $(data.input).parents('.dropdown-box').find('.palce-value').show();
                } else {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').show();
                    $(data.input).parents('.dropdown-box').find('.palce-value').hide();
                }
            },
            onUpdate: function (data) {
                $(data.input).parent('.rangeSlider').find('.js-rang-val').text(data.from);
                $(data.input).parents('.dropdown-box').find('.js-rang-val').text(data.from);
                if (data.from > 0) {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').hide();
                    $(data.input).parents('.dropdown-box').find('.palce-value').show();
                } else {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').show();
                    $(data.input).parents('.dropdown-box').find('.palce-value').hide();
                }
            }
        });
    });
}

function initRangesliderPrice(target) {
    var target = $(target);
    var minval = target.data('minval') !== '' ? target.data('minval'): 0;
    var maxval = target.data('maxval') !== '' ? target.data('maxval'): 5000;
    var maxpostfix = target.data('maxpostfix');
    target.each(function (i, e) {
        $(e).ionRangeSlider({
            grid: false,
            min: minval,
            max: maxval,
            from: 0,
            max_postfix: maxpostfix,
            step: 1,
            onStart: function (data) {
                $(data.input).parent('.rangeSlider').find('.js-rang-val').text(formatPrice(data.from));
                $(data.input).parents('.dropdown-box').find('.js-rang-val').text(formatPrice(data.from));
            },
            onChange: function (data) {
                $(data.input).parent('.rangeSlider').find('.js-rang-val').text(formatPrice(data.from));
                $(data.input).parents('.dropdown-box').find('.js-rang-val').text(formatPrice(data.from));
                if (data.from > 0) {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').hide();
                    $(data.input).parents('.dropdown-box').find('.palce-value').show();
                } else {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').show();
                    $(data.input).parents('.dropdown-box').find('.palce-value').hide();
                }
            },
            onUpdate: function (data) {
                $(data.input).parent('.rangeSlider').find('.js-rang-val').text(formatPrice(data.from));
                $(data.input).parents('.dropdown-box').find('.js-rang-val').text(formatPrice(data.from));
                if (data.from > 0) {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').hide();
                    $(data.input).parents('.dropdown-box').find('.palce-value').show();
                } else {
                    $(data.input).parents('.dropdown-box').find('.palce-holder').show();
                    $(data.input).parents('.dropdown-box').find('.palce-value').hide();
                }
            }
        });
    });
}
function formatPrice(value) {
    var str = value.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 4) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
}

function dropdownopen(target) {
    $('body').addClass('is--dropdown');
    $(target).addClass('is--active');
    $(target).parent('.dropdown-box').addClass('show-dd');
}

function dropdownclose() {
    $('body').removeClass('is--dropdown');
    $('.js-dropdownbtn').removeClass('is--active');
    $('.js-dropdownbtn').parent('.dropdown-box').removeClass('show-dd');
}

function videoPopup(target) {
    var $target = $(target);
    var videoUrl;
    var vidPlayer = null;
    if (winWidth < 768) {
        videoUrl = $target.data('mobile-url');
    } else {
        videoUrl = $target.data('desktop-url');
    }
    var videoClass = $target.data('video-class');
    var videoWidth = $target.data('width');
    var videoHeight = $target.data('height');
    var videoType = $target.data('video-type');
    var videoPoster = $target.data('video-poster') || null;
    var techOrder = ['html5', 'youtube'];
    var $content = '<div class="popup-video op-0"><video id="lightboxVideo" width="' + videoWidth +
        '" height="' + videoHeight +
        '" preload="auto" controls autoplay class="video-js vjs-layout-large" data-setup="{}"><source src="' +
        videoUrl + '" type="video/mp4" /><p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener noreferrer">supports HTML5 video</a></p></video></div>';
    $.fancybox.open({
        type: 'html',
        content: $content,
        beforeShow: function () {
            $('body').addClass('is--videopopup');
            $('.fancybox-container').addClass(videoClass);
        },
        afterShow: function () {
            vidPlayer = videojs('lightboxVideo', function () {
                techOrder
            });
            vidPlayer.src({
                type: videoType === 'youtube' ? 'video/youtube' : 'video/mp4',
                src: videoType === 'youtube' ?
                    'https://www.youtube.com/watch?v=' + videoUrl : videoUrl
            });
            if (videoPoster) vidPlayer.poster(videoPoster);
            vidPlayer.on('ready', function () {
                vidPlayer.play();
            });
            $('.popup-video').animate({
                'opacity': '1'
            }, 500);
        },
        beforeClose: function () {
            $('body').removeClass('is--videopopup');
            videojs('lightboxVideo').dispose();
        }
    });
}

function initslider(target) {
    $(target).slick({
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        rtl: isRTL,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
            }
        }]
    });
};

function initslidermob() {
    if (winWidth < 768) {
        $('.js-mob-slider-1').slick({
            dots: false,
            arrows: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: isRTL,
        });
    } else {
        $('.js-mob-slider-1.slick-initialized').each(function () {
            $(this).slick('unslick');
        });
    }
}

jsCoverSlider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: jsCoverthumbSlider,
    rtl: isRTL,
    infinite: true,
});

jsCoverthumbSlider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: jsCoverSlider,
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    centerPadding: 0,
    infinite: true,
    swipeToSlide: true,
    rtl: isRTL,
    responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

function initMobSlider() {
    if (winWidth < 992) {
        if ($('.js-mob-slider').length > 0) {
            $('.js-mob-slider').each(function () {
                $(this).not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    swipeToSlide: true,
                    rtl: isRTL,
                    responsive: [{
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }],
                });
            });
        }
    } else {
        $('.js-mob-slider.slick-initialized').each(function () {
            $(this).slick('unslick');
        });
    }
    if (winWidth < 768) {
        if ($('.js-vision-wrapper').length > 0) {
            $('.js-vision-wrapper').each(function () {
                $(this).not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    swipeToSlide: true,
                    rtl: isRTL,
                });
            });
        }
    } else {
        $('.js-vision-wrapper.slick-initialized').each(function () {
            $(this).slick('unslick');
        });
    }
}

$(document).on("focus", ".js-input-focus", function (e) {
    $(this).parent().addClass('is--focus');
})

$(document).on("blur", ".js-input-focus", function (e) {
    inputValue = $(this).val().length;
    if (!inputValue > 0) {
        $(this).parent().removeClass('is--focus');
    }
});

function checkFieldval(element) {
    $(element).each(function (i, index) {
        inputValue = $(index).val().length;
        if (inputValue > 0) {
            $(index).parent().addClass('is--focus');
        }
    });
}

function dataSrc() {
    if (winWidth < 768) {
        $('[data-mobile-src]').each(function () {
            var thisSrc = $(this).attr('data-mobile-src');
            $(this).attr('src', thisSrc);
        });
        $('[data-mobile-poster]').each(function () {
            var thisSrc = $(this).attr('data-mobile-poster');
            $(this).attr('poster', thisSrc);
        });
    } else {
        $('[data-desktop-src]').each(function () {
            var thisSrc = $(this).attr('data-desktop-src');
            $(this).attr('src', thisSrc);
        });
        $('[data-desktop-poster]').each(function () {
            var thisSrc = $(this).attr('data-desktop-poster');
            $(this).attr('poster', thisSrc);
        });
    }
}

var videoPlayerIdIndex = 1;

function videoPlayerInit() {
    $('.my-video-js').each(function () {
        var thisId = $(this).attr('id');
        $(this).addClass('video-js');
        if (!thisId == '') {
            thisId = 'video-id-' + videoPlayerIdIndex;
            $(this).attr('id', thisId);
        }
        var player = videojs(thisId);
        videoPlayerIdIndex++;
    });
}

function addClassOnHover(target) {
    if (winWidth > 991) {
        $(target).on('mouseover', function () {
            var $this = $(this);
            $this.parents().find('.is--hover').removeClass('is--hover');
            $this.addClass('is--hover');
        }).on('mouseout', '.red-overlay', function () {
            var $this = $(this);
            setTimeout(function () {
                $this.parents('.ins-thumb-wrapper').removeClass('is--hover');
            }, 500);
        });
    }
}

function initFilterPopup() {
    // Open Filter
    $('.js-filter-popup:not(.js-filter-popup--binded)').click(function (e) {
        e.preventDefault();
        $('html').addClass('open-filter-popup');
    });
    $('.js-filter-popup').addClass('js-filter-popup--binded');

    // Close Filter
    $('.js-filter-popup-close:not(.js-filter-popup-close--binded)').click(function (e) {
        e.preventDefault();
        $('html').removeClass('open-filter-popup');
    });
    $('.js-filter-popup-close').addClass('js-filter-popup-close--binded');

    // Reset Filter
    $('.js-filter-popup-reset:not(.js-filter-popup-reset--binded)').click(function (e) {
        e.preventDefault();
        var searchBar = $('.mobile-filter .search-box input');
        var slider = $(".mobile-filter .js-rangeSlider");
        var sliderPrice = $(".mobile-filter .js-rangeSlider-price");
        var datePicker = $('.mobile-filter .js-flatpickr');
        var select = $('.js-select2-multi');
        var select1 = $('.js-select2');


        if (searchBar.get(0)) {
            searchBar.val('');
        }
        if (slider.get(0)) {
            slider.data("ionRangeSlider").reset();
        }
        if (sliderPrice.get(0)) {
            sliderPrice.data("ionRangeSlider").reset();
        }

        if (datePicker.get(0)) {
            initDatepicker('.mobile-filter .js-flatpickr');
        }

        if (select.get(0)) {
            select.val(0).trigger("change");
        }

        if (select1.get(0)) {
            select1.val(0).trigger("change");
        }
    });
    $('.js-filter-popup-reset').addClass('js-filter-popup-reset--binded');
}

if ($('.js-intl-input').length) {
    var utilsPath = 'assets/js/utils.js';
    var input = document.querySelector(".js-intl-input");
    window.intlTelInput(input, {
        initialCountry: "ae",
        separateDialCode: true,
        dropdownContainer: document.body,
        utilsScript: utilsPath,
        rtl: true
    });
}

function validation(target) {
    if ($(target).length) {
        var $target = $(target);
        $target.each(function (i, e) {
            var v = $target.eq(i).validate({
                errorElement: 'span',
                errorClass: "invalid-field",
                rules: {
                    intl: {
                        number: true
                    }
                },
                highlight: function (element) {
                    $(element).parents('.form-group').addClass("invalid-field");
                },
                unhighlight: function (element) {
                    $(element).parents('.form-group').removeClass("invalid-field");
                },
                errorPlacement: function (error, element) {},
                submitHandler: function (form) {
                    if (grecaptcha.getResponse() == '') {
                        $('#reCaptchaError').html('<p>Please verify you are human</p>');
                    } else {
                        formSubmit(form);
                    }
                }
            });
        });
    }
}

function setBotNavBar() {
    if (winWidth < 992) {
        menuBox.prependTo(".body-wrapper");
    } else {
        menuBox.prependTo(".header");
    }
}

function pageLoaded() {
    $('body').addClass('page-loaded');
    setTimeout(function () {
        AOS.init({
            duration: 600,
            once: true,
            offset: animationStartoffset,
            easing: 'ease',
        });
    }, 200);
}

function dataTrim() {
    var ellipsis = "...";
    $('[data-trim]').each(function () {
        var text = $(this).html();
        var charLimit = parseInt($(this).attr('data-trim'));
        $(this).html(TrimLength(text, charLimit));
        $(this).addClass('is--trimmed');
    });

    function TrimLength(text, maxLength) {
        text = $.trim(text);
        if (text.length > maxLength) {
            text = text.substring(0, maxLength - ellipsis.length);
            return text.substring(0, text.lastIndexOf(" ")) + ellipsis;
        } else return text;
    }
}

function loadRecaptcha(element) {
    var _self = $(element);
    if (_self.length > 0) {
        var apiKey = _self.attr('data-apiKey');
        var script = document.createElement('script');
        script.onload = function () {
            grecaptcha.ready(function () {
                grecaptcha.execute(apiKey, {
                    action: 'homepage'
                }).then(function (token) {});
            });
        };
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + apiKey;
        script.async = true;
        document.head.appendChild(script);
    }
}

function addOnLoadAnimation() {
    $('.container > h1, .error-page-404 .btn, .c-timeline2, .headingwithcta, .sec-facts-counter, .sec-about-us, .about-content, .home-banner, .content-page, .sec-content-home').attr('data-aos', 'fade-up');
    $('.c-grid.five-column, .c-grid.five-column + .d-flex, .fullscreen-video, .sec-whatshappening .container > .row').attr('data-aos', 'fade-up');
    $('.sec-whatshappening .container > .row + .d-flex, .sec-facts-counter, .sec-abudhabiart-world, .sec-subscribe, .sec-about-us, .sec-vision .vision-wrapper .vision-box').attr('data-aos', 'fade-up');
    $('.sec-thumbs-handler, .sec-thumbs-handler p .sec-vision .vision-wrapper .vision-box .vision-content .content-box, .sec-about-us .about-content h1').attr('data-aos', 'fade-up');
    $('.home-banner + .sec-padded, .sec-vision, .c-grid.five-column.instructors-sty1 .c-column, .filters-row, .b-thumb-wrapper.has--banner, .thumb-wrapper .b-thumb-wrapper').attr('data-aos', 'fade-up');
    $('.sec-category-detail .category-wrapper, .sec-about-instructor, .sec-about-instructor .sec-heading, .sec-about-instructor .instructor-slider, .sec-video-popup').attr('data-aos', 'fade-up');
    $('.sec-video-popup .sec-heading, .sec-map, .sec-form, .sec-thank-you, .no-result-banner, .error-page-404 .error-title, .error-page-404 h1, .error-page-404 p').attr('data-aos', 'fade-up');
    $('.videopopup-style .videopopup-wrapper .video-text').attr('data-aos', 'fade-left');
    $('.cover-slider .cover-item .cover-content').attr('data-aos', 'no');
    $('.sec-vision .vision-wrapper .vision-box').each(function (i) {
        $(this).attr('data-aos-delay', 50 + (50 * i));
    });
}

function elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
    );
}

function counter() {
    $('.js-counter').each(function (i, ele) {
        var $this = $(ele);
        if (elementInViewport(ele) && !$this.hasClass('has--complete')) {
            $this.prop('Counter', 0).animate({
                Counter: $this.data('value')
            }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                    $this.text(Math.ceil(now));
                }
            });
            $this.addClass('has--complete');
        }
    });
}

var map;

function initMap() {
    var location = {
        lat: 24.4704995,
        lng: 54.3446222
    };
    map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: location,
        disableDefaultUI: true,
        fullscreenControl: true,
    });
}

function initFulscreen() {
    $('.fulscreen-btn').click(function () {
        $('button[aria-label^="Toggle"]').trigger('click');
    })
}


customScrollBar();
menuclose();
menuOpen();
function customScrollBar() {
    $('.js-custom-scroll-bar').mCustomScrollbar();
    $('.c-event-banner .event-txt-box .time').mCustomScrollbar({
        theme: "minimal-dark",
        // setHeight: 150,
    });
    if (winWidth < 992) {
        $('.js-custom-scroll-bar').mCustomScrollbar("destroy");
    }
}
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        closePopup();
        $('.js-langSelector').find('ul').removeClass('active');
        $('.menu-sec').removeClass('is--active');
        setTimeout(function () {
            $('body, html').removeClass('menu-open');
        }, 350);
        $('.js-childDropdown').removeClass('active');
    }
});
$(document).on('click', '.menu-sec.is--active', function (e) {
    if ($(e.target).closest('.menu-inner, .main-nav').length === 0) {
        $('.menu-sec .close-menu').click();
    }
})
function menuOpen() {
    $(".hamburger a").each(function (index) {
        $(this).on("click", function (e) {
            e.preventDefault();
            hamPosition = $(this)[0].getBoundingClientRect();
            $('.close-menu').css({
                'left': hamPosition.left,
                'top': hamPosition.top,
            })
            $('.menu-sec').addClass('is--active');
            setTimeout(function () {
                $('body, html').addClass('menu-open');
            }, 100);
            if (winWidth < 992) {
                var posTop = '-' + $(window).scrollTop();
                //console.log(posTop);
                $('html').css({
                    'position': 'fixed',
                    'top': posTop + 'px',
                })
            }
        });
    });
}

function menuclose() {
    $('.close-menu').on('click', function (e) {
        e.preventDefault();
        $('.menu-sec').removeClass('is--active');
        setTimeout(function () {
            $('body, html').removeClass('menu-open');
        }, 350);
        if (winWidth < 992) {
            var topPos = $('html').css('top');
            if (topPos != "0px") {
                var makInt = topPos.split('-')[1].split('px')[0];
            }
            $('html').css({
                'position': 'relative',
                'top': 'auto',
            });
            $(window).scrollTop(parseInt(makInt));
        }
    });
}
var imgAssetURL = 'assets/img';
initSlick();
function initSlick() {
    if (isRTL) {
        var bannerPrev = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><img src="' + imgAssetURL + '/icons/angle-right.svg" alt="caret-left" class="toSvg js-tosvg svgBlack" /></button>'
        var bannerNext = '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><img src="' + imgAssetURL + '/icons/angle-left.svg" alt="caret-right" class="toSvg js-tosvg svgBlack" /></button>'
    } else {
        var bannerPrev = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><img src="' + imgAssetURL + '/icons/angle-left.svg" alt="caret-left" class="toSvg js-tosvg svgBlack" /></button>'
        var bannerNext = '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><img src="' + imgAssetURL + '/icons/angle-right.svg" alt="caret-right" class="toSvg js-tosvg svgBlack" /></button>'
    }
    
    $('.js-timeline2-carousel').each(function () {
        $(this).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            swipeToSlide: true,
            focusOnSelect: true,
            rtl: isRTL,
            prevArrow: bannerPrev,
            nextArrow: bannerNext,
            appendArrows: $(this).parent().find('.js-timeline2-carousel-arrows'),
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }],
                
        });
    });


    $('.js-eventsCarousel').each(function (e) {
        var appendArrowsIn = $(this).next('.eventsCarouselNav');
        $(this).slick({
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: false,
            infinite: true,
            rtl: isRTL,
            focusOnSelect: true,
            appendArrows: appendArrowsIn,
            prevArrow: bannerPrev,
            nextArrow: bannerNext,
            responsive: [{
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        // infinite: false,
                        centerMode: false,
                    }
                }
            ],
        });
    })
    	$('.js-captionSlider').slick({
    	    autoplay: true,
    	    autoplaySpeed: 5000,
    	    speed: 1000,
    	    slidesToShow: 1,
    	    slidesToScroll: 1,
    	    dots: true,
    	    arrows: false,
    	    fade: false,
    	    draggable: true,
    	    infinite: true,
    	    rtl: isRTL,
    	    appendArrows: '.js-transparent-btn',
    	    asNavFor: '.js-figureSlider, .js-figureCaptionSlider',
    	    appendDots: '.js-slideNumber',
    	    customPaging: function (slider, i) {
    	        if (i < 9) {
    	            return '0' + (i + 1) + '<small> / 0' + slider.slideCount + '</small>';
    	        } else {
    	            return '' + (i + 1);
    	        }
    	    },
    	});

    	$('.js-captionSlider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    	    $(this).parent().find('.bricks .slide').removeClass('is--active');
    	    $(this).parent().find('.bricks .slide:nth-child(' + (nextSlide + 1) + ')').addClass('is--active');
    	});


    	$('.js-figureSlider').slick({
    	    autoplay: true,
    	    autoplaySpeed: 5000,
    	    speed: 1000,
    	    slidesToShow: 1,
    	    slidesToScroll: 1,
    	    dots: false,
    	    arrows: true,
    	    fade: true,
    	    infinite: true,
    	    rtl: isRTL,
    	    appendArrows: '.js-transparent-btn-slide',
    	    asNavFor: '.js-captionSlider, .js-figureCaptionSlider',
    	});
    	$('.js-figureCaptionSlider').slick({
    	    autoplay: true,
    	    autoplaySpeed: 5000,
    	    speed: 1000,
    	    slidesToShow: 1,
    	    slidesToScroll: 1,
    	    dots: false,
    	    arrows: false,
    	    fade: false,
    	    draggable: false,
    	    infinite: true,
    	    rtl: isRTL,
    	    asNavFor: '.js-captionSlider, .js-figureSlider',
    	});
    // $('.js-captionSlider').slick({
    //     pauseOnHover: true,
    //     pauseOnDotsHover: true,
    //     autoplay: true,
    //     autoplaySpeed: 4000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     dots: true,
    //     arrows: false,
    //     fade: true,
    //     draggable: true,
    //     infinite: true,
    //     rtl: isRTL,
    //     asNavFor: '.js-figureSlider, .js-figureCaptionSlider',
    //     appendDots: '.js-slideNumber',
    //     customPaging: function (slider, i) {
    //         var currentSlideLabel = "0";
    //         var totalSlidesLabel = "0";
    //         if (i > 8) {
    //             currentSlideLabel = "";
    //         }
    //         if (slider.slideCount > 8) {
    //             totalSlidesLabel = "";
    //         }
    //         return currentSlideLabel + (i + 1);
    //     },
    // });
    // $('.js-figureSlider').slick({
    //     pauseOnHover: true,
    //     pauseOnDotsHover: true,
    //     autoplay: true,
    //     autoplaySpeed: 4000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     dots: false,
    //     arrows: true,
    //     fade: false,
    //     infinite: true,
    //     rtl: isRTL,
    //     appendArrows: '.homeCarouselNav',
    //     prevArrow: bannerPrev,
    //     nextArrow: bannerNext,
    //     asNavFor: '.js-captionSlider, .js-figureCaptionSlider',
    //     responsive: [{
    //         breakpoint: 767,
    //         settings: {
    //             appendArrows: '.homeCarouselNav-mobile',
    //         }
    //     }],
    // });
    delayAutoPlay('.js-figureSlider');
    delayAutoPlay('.js-captionSlider');
    pauseAttachedSlider('.js-figureSlider, .js-captionSlider', '.js-figureSlider', '.js-captionSlider');
    // $('.js-figureCaptionSlider').slick({
    //     autoplay: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     dots: false,
    //     arrows: false,
    //     fade: false,
    //     draggable: false,
    //     infinite: false,
    //     rtl: isRTL,
    //     asNavFor: '.js-captionSlider, .js-figureSlider',
    // });
    
    $('.js-image-slider').each(function (e) {
        var appendArrowsIn = $(this).next('.js-ImageSliderNav');
        var appendDotsIn = $(this).prev('.js-ImageSliderIndicator');
        $(this).slick({
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            fade: true,
            draggable: true,
            infinite: false,
            rtl: isRTL,
            appendDots: appendDotsIn,
            appendArrows: appendArrowsIn,
            prevArrow: bannerPrev,
            nextArrow: bannerNext,
            customPaging: function (slider, i) {
                var currentSlideLabel = "0";
                var totalSlidesLabel = "0";
                if (i > 8) {
                    currentSlideLabel = "";
                }
                if (slider.slideCount > 8) {
                    totalSlidesLabel = "";
                }
                return currentSlideLabel + (i + 1) + '<small> / ' + totalSlidesLabel + slider.slideCount + '</small>';
            },
        });
    });
    $('.js-carousel-timeline-links').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        focusOnSelect: true,
        rtl: isRTL,
    });
    $('.js-carousel-timeline').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        pauseOnFocus: true,
        rtl: isRTL,
        swipeToSlide: true,
        touchThreshold: 4,
        responsive: [{
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            }
        }, {
            breakpoint: 525,
            settings: {
                slidesToShow: 1,
            }
        }],
    });
    setTimelineLinksHightlight();
    $('.js-carousel-timeline-links').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        setTimelineLinksHightlight();
    });
    $('.js-carousel-timeline-links').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        setTimelineLinksHightlight();
    });
    $('.js-carousel-timeline').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if (winWidth < 768) {
            $(this).closest('.c-timeline').find('.timeline-background .bg-img').removeClass('is--active');
            $(this).closest('.c-timeline').find('.timeline-background .bg-img:nth-child(' + (nextSlide + 1) + ')').addClass('is--active');
        }
    });
    var totalSlide,
        curSlide,
        getPrecentage;
    $('.js-event-detail').on('init', function (event, slick) {
        curSlide = slick.currentSlide + 1;
        totalSlide = slick.slideCount;
        getPrecentage = (curSlide / totalSlide) * 100;
        $('.js-slickIndicator #slick-progress').find('span').width(getPrecentage + '%');
        $('.js-slickIndicatorMobile #slick-progress-mobile').find('span').width(getPrecentage + '%');
    });
    $('.js-event-detail').each(function (e) {
        var appendArrowsIn = $(this).parents().find('.js-sliderArrow');
        var appendDotsIn = $(this).prev('.js-slickIndicator');
        var appendArrowsInMob = $(this).parents().find('.js-sliderArrowMobile');
        var appendDotsInMob = $(this).parents('.c-text-slider-wrap').prev('.js-slickIndicatorMobile');
        $(this).slick({
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            fade: true,
            draggable: true,
            infinite: true,
            rtl: isRTL,
            appendDots: appendDotsIn,
            appendArrows: appendArrowsIn,
            prevArrow: bannerPrev,
            nextArrow: bannerNext,
            responsive: [{
                breakpoint: 768,
                settings: {
                    appendDots: appendDotsInMob,
                    appendArrows: appendArrowsInMob,
                }
            }],
            customPaging: function (slider, i) {
                var currentSlideLabel = "0";
                var totalSlidesLabel = "0";
                if (i > 8) {
                    currentSlideLabel = "";
                }
                if (slider.slideCount > 8) {
                    totalSlidesLabel = "";
                }
                return '<small>' + currentSlideLabel + (i + 1) + '</small><small>' + totalSlidesLabel + slider.slideCount + '</small>';
            },
            //asNavFor: '.js-img-swipper'
        });
    });
    // $('.js-img-swipper').slick({
    // 	slidesToShow: 1,
    // 	slidesToScroll: 1,
    // 	dots: false,
    // 	arrows: false,
    // 	rtl: isRTL,
    // 	asNavFor: '.js-event-detail'
    // });
    $('.js-event-detail').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var going = "next";
        if (currentSlide > nextSlide) {
            going = 'prev';
        }
        if (currentSlide == (slick.$slides.length - 1) && nextSlide == 0) {
            going = "next";
        }
        if (currentSlide == 0 && nextSlide == (slick.$slides.length - 1)) {
            going = "prev";
        }
        if (slick.$slider.closest('.sec-daily-events').find('.js-img-swipper').get(0)) {
            if (!slick.$slider.closest('.sec-daily-events').find('.js-img-swipper').hasClass('animating--dragged')) {

                if (going == 'next') {
                    var currentSlideItem = slick.$slider.closest('.sec-daily-events').find('.js-img-swipper .img-swipper:first-child');
                    if (isRTL) {
                        currentSlideItem.css('left', 'auto').animate({
                            opacity: 0,
                            right: -100,
                        }, 'fast');
                    } else {
                        currentSlideItem.animate({
                            opacity: 0,
                            left: -100,
                        }, 'fast');
                    }
                    setTimeout(function () {
                        currentSlideItem.appendTo(currentSlideItem.parent());
                        currentSlideItem.removeAttr('style');
                    }, 300);
                } else {
                    var currentSlideItem = slick.$slider.closest('.sec-daily-events').find('.js-img-swipper .img-swipper:last-child');
                    if (isRTL) {
                        currentSlideItem.prependTo(currentSlideItem.parent()).css({
                            opacity: 0,
                            right: -100,
                            left: 'auto'
                        });
                        currentSlideItem.animate({
                            opacity: 1,
                            right: 0,
                        }, 'fast');
                    } else {
                        currentSlideItem.prependTo(currentSlideItem.parent()).css({
                            opacity: 0,
                            left: -100
                        });
                        currentSlideItem.css('right', 'auto').animate({
                            opacity: 1,
                            left: 0,
                        }, 'fast');
                    }
                    setTimeout(function () {
                        currentSlideItem.removeAttr('style');
                    }, 300);
                }
            }
        }

        //Imran
        curSlide = nextSlide + 1;
        totalSlide = slick.slideCount;
        getPrecentage = (curSlide / totalSlide) * 100;
        $('.js-slickIndicator #slick-progress').find('span').width(getPrecentage + '%');
        $('.js-slickIndicatorMobile #slick-progress-mobile').find('span').width(getPrecentage + '%');
    });
    $('.js-event-list-carousel').slick({
        appendArrows: '.event-list-carousel-nav',
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        asNavFor: '.js-event-list-content-carousel',
        rtl: isRTL,
        fade: true,
    });
    $('.js-event-list-content-carousel').slick({
        asNavFor: '.js-event-list-carousel',
        rtl: isRTL,
        arrows: false,
    });
    $('.js-tickets-detail').on('init', function (event, slick) {
        curSlide = slick.currentSlide + 1;
        totalSlide = slick.slideCount;
        getPrecentage = (curSlide / totalSlide) * 100;
        $('#slick-progress').find('span').width(getPrecentage + '%');
    });

    $('.js-tickets-detail').slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        fade: false,
        draggable: true,
        infinite: false,
        focusOnSelect: true,
        rtl: isRTL,
        appendDots: '.js-slickIndicator',
        appendArrows: '.js-sliderArrow',
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        customPaging: function (slider, i) {
            var currentSlideLabel = "0";
            var totalSlidesLabel = "0";
            if (i > 8) {
                currentSlideLabel = "";
            }
            if (slider.slideCount > 8) {
                totalSlidesLabel = "";
            }
            return '<small>' + currentSlideLabel + (i + 1) + '</small><small>' + totalSlidesLabel + slider.slideCount + '</small>';
        },
    });
    $('.js-tickets-detail').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        curSlide = nextSlide + 1;
        totalSlide = slick.slideCount;
        getPrecentage = (curSlide / totalSlide) * 100;
        $('#slick-progress').find('span').width(getPrecentage + '%');
    });
    $('.js-customScrollWrapper').slick({
        autoplay: false,
        variableWidth: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        focusOnSelect: true,
        rtl: isRTL,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });
    $('.js-detailSlider').slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: false,
        draggable: false,
        infinite: false,
        rtl: isRTL,
    });
}

function pauseAttachedSlider(parentClass, slickSlider1, slickSlider2) {
    $(parentClass).hover(function () {
        $(slickSlider1).slick('slickPause');
        $(slickSlider2).slick('slickPause');
    }, function () {
        $(slickSlider1).slick('slickPlay');
        $(slickSlider2).slick('slickPlay');
    });
}

function initPageFooter() {
    if ($('.body-wrapper').length){
        $('footer').addClass('is--fixed');
        var winHeight = $(window).outerHeight(),
        footer = $('footer').outerHeight(),
        contentHeight = $('.body-wrapper').outerHeight(),
        headerHeight = $('header').outerHeight();
        
        if (winHeight - footer <= contentHeight + headerHeight) {
            $('footer').removeClass('is--fixed');
        }
    } else {
        $('footer').removeClass('is--fixed')
    }
}

function delayAutoPlay(slickItem) {
    $(slickItem).slick('slickPause');
    setTimeout(function () {
        $(slickItem).slick('slickPlay');
    }, 2000);
}
var carouselTimelineLinks = $('.js-carousel-timeline-links .item').length;

function setTimelineLinksHightlight() {
    $('.js-carousel-timeline-links .slick-slide').removeClass(function (index, className) {
        return (className.match(/(^|\s)is-next-item-shade--\S+/g) || []).join(' ');
    });
    $('.js-carousel-timeline-links .slick-slide').removeClass(function (index, className) {
        return (className.match(/(^|\s)is-prev-item-shade--\S+/g) || []).join(' ');
    });
    var activeSlide = $('.js-carousel-timeline-links .slick-slide.slick-current.slick-active');
    var x = 0;
    for (var i = carouselTimelineLinks - 1; i >= 0; i--) {
        x = i;
        if (i >= 8) {
            x = 8;
        }
        activeSlide.nextAll().slice(i - 1, i).addClass('is-next-item-shade--' + x);
        activeSlide.prevAll().slice(i - 1, i).addClass('is-prev-item-shade--' + x);
    }
}
scrollToSec('.js-scroll-anchor', '.js-scroll-sec');
var fixedSidebar = $('.js-fixed-sidebar').height();
$('.js-scroll-sec').css('minHeight', fixedSidebar + 100);

function scrollToSec(anchor, section) {
    var $thisAnchor = $(anchor);
    var $thisSection = $(section);
    $thisAnchor.find('a').click(function (e) {
        e.preventDefault();
        $thisAnchor.find('a').parent().removeClass('active')
        $(this).parent().addClass('active');
        var targetSection = $(this).attr('href').split('#')[1];
        var sectionPos = $thisSection.find('[data-link="' + targetSection + '"]').offset().top;
        moveTo(sectionPos);
    })
}
function moveTo(position) {
    var headerHeight = $('.c-sticky-header').outerHeight();
    $("html, body").animate({
        scrollTop: position - headerHeight
    }, "slow");
}
fixedToScroll();
scroll();
var waiting = false,
    endScrollHandle;
$(window).scroll(function () {
    if (waiting) {
        return;
    }
    waiting = true;
    // clear previous scheduled endScrollHandle
    clearTimeout(endScrollHandle);
    scroll('dontRush');
    setTimeout(function () {
        waiting = false;
    }, 100);
    // schedule an extra execution of scroll() after 200ms
    // in case the scrolling stops in next 100ms
    endScrollHandle = setTimeout(function () {
        scroll();
    }, 200);
});
function fixedToScroll() {
    if ($('.js-fixed-sidebar').length > 0) {
        $('.js-fixed-sidebar').css('width', $('.js-fixed-sidebar').parent().width())
        var footerOffset = $('.c-main-footer').offset().top;
        var scrollOffset = $('.js-fixed-sidebar').offset().top;
        var sidebarHeight = $('.js-fixed-sidebar').outerHeight();
        var forFixedOffset = scrollOffset - headerHeight + 26;
        var forBottomOffset = footerOffset - 70 - sidebarHeight - headerHeight;
        $(window).scroll(function () {
            var winScroll = $(this).scrollTop();
            if (winScroll > forFixedOffset) {
                $('.js-fixed-sidebar').addClass('is--fixed');
                $('.js-fixed-sidebar').removeClass('is--bottom');
                if (winScroll > forBottomOffset) {
                    $('.js-fixed-sidebar').addClass('is--bottom');
                }
            } else {
                $('.js-fixed-sidebar').removeClass('is--fixed is--bottom');
            }
        });
    }
}

function elementInViewport2(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;
    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }
    return (
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
    );
}
var scroll = function (rush) {
    $('[data-link]').each(function () {
        var sdx = $(this).position().top;
        var wx = $(window).scrollTop();
        var dp = winHeight / 2;
        var dds = $(this).position().top - $(window).scrollTop();
        if (elementInViewport2(this)) {
            var thisValue = $(this).attr('data-link');
            if (dds < dp) {
                $('.js-scroll-anchor li').removeClass('active');
                $('.js-scroll-anchor a[href="#' + thisValue + '"]').parent().addClass('active')
            }
        }
    });
};
var waiting = false,
    endScrollHandle;
$(window).scroll(function () {
    if (waiting) {
        return;
    }
    waiting = true;
    // clear previous scheduled endScrollHandle
    clearTimeout(endScrollHandle);
    scroll('dontRush');
    setTimeout(function () {
        waiting = false;
    }, 100);
    // schedule an extra execution of scroll() after 200ms
    // in case the scrolling stops in next 100ms
    endScrollHandle = setTimeout(function () {
        scroll();
    }, 200);
});
actionsOnClick();
initDatePicker();
function actionsOnClick() {
    $(".js-langSelector").on("click", function (e) {
        e.preventDefault(), $(this).find("ul").toggleClass("active")
    }), $(".js-datelinerDropdown").on("click", function (e) {
        e.preventDefault(), $(this).siblings(".dropdownBox").slideToggle()
    }), $(".hasDropdown a").on("click", function (e) {
        e.preventDefault(), $(this).siblings(".js-childDropdown").toggleClass("active")
    }), $(".js-mobilefilters a").on("click", function () {
        $(".c-collapseable").removeClass("showing"), $(".accordion-content").slideUp("slow")
    })
}

$(".c-collapseable").click(function (e) {
    if (winWidth < 768) {
        var t = $(this).attr("data-accordion"),
            i = $(this).attr("data-revert");
        e.preventDefault(), i && ($("[data-accordion], .c-collapseable").not(this).removeClass("showing"), $(".ContentBox, .accordion-content").not(t).slideUp("slow")), t ? ($(this).toggleClass("showing"), $(t).slideToggle("slow")) : ($(this).toggleClass("showing"), $(this).siblings(".ContentBox").slideToggle("slow"));
    }
});

function initDatePicker(argument) {
    if (isRTL) {
        monthnamelabel = ['يناير', 'فبراير ', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر	'];
    } else {
        monthnamelabel = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }
    $(".c-datepicker").each(function () {
        $(this).datepicker({
            dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            minDate: 0,
            monthNames: monthnamelabel,
            // maxDate: "+4M",			
            onSelect: function (dateText, inst) {
                var currentDate = new Date();
                var date = $(this).val();
                filterByValue = 'day';
                $('.js-childDropdown.active', this).removeClass('active');
                $('.c-collapseable').removeClass('showing');
                $('.accordion-content').slideUp('slow');
                var selectDate = new Date(date);
                showDays(selectDate, currentDate);
                $('.js-date').attr('data-filter-start-date', selectDate.toInputFormat());
                $('.js-date').attr('data-filter-end-date', selectDate.toInputFormat());
                $('.js-day').attr('data-filter-start-date', selectDate.toInputFormat());
                $('.js-day').attr('data-filter-end-date', selectDate.toInputFormat());
                $('.js-date').parent().addClass('active');
                $('.js-childDropdown').removeClass('active');
                $('.js-filter-label').html(weekday[selectDate.getDay()]);
                var filterDateValue = $.datepicker.formatDate('dd M yy', new Date(selectDate));
                filterDateValue = arabicDateCheck(filterDateValue);
                $('.js-filter-value').html(filterDateValue);
                mobileDate = 1;
                mobileDateValue = selectDate.toInputFormat();
                getDataCalenderMain();
                setTimeout(function () {
                    dataTrim();
                    ChangeToSvg();
                }, 600);
            }
        });
    })
}
$(window).on("scroll", function(e) {
    onTopCheck()
})
function onTopCheck() {
    $(window).scrollTop() > 300 ? $("body").addClass("is--not-on-top") : $("body").removeClass("is--not-on-top")
}

$('.js-formsModal').on('show.bs.modal', function () {
    $(this).find('.js-select2').each(function(i, e){
        var tarPar = $(e).parent('.select2-box');
        var tarPlace = $(e).data('placeholder');
        var dataClass = $(e).data('class');
        $(e).select2({
            dropdownParent: tarPar,
            minimumResultsForSearch: Infinity,
            width: '100%',
            placeholder: tarPlace,
            dir: isRTL,
            theme: dataClass,
        });
        console.log($(this));
    });
});